`<template>
  <div class="card card-custom">
    <b-form inline @submit.stop.prevent="onSubmit" novalidate class="d-block">
      <div class="card-body d-flex flex-column align-items-stretch">
        <div class="b-form-inline-group row">
          <label
            for="input-model"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >Model</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-model"
              name="input-model"
              v-model="$v.form.model.$model"
              :state="validateState('model')"
              aria-describedby="input-model-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-model-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>

        <div class="b-form-inline-group row">
          <label
            for="input-serial"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("PAGES.SALES_MANAGEMENT.POS.SERIAL") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-serial"
              name="input-serial"
              v-model="$v.form.serial.$model"
              :state="validateState('serial')"
              aria-describedby="input-serial-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-serial-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>

        <div class="b-form-inline-group row">
          <label
            for="input-pass"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("PAGES.SALES_MANAGEMENT.POS.PASS") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-pass"
              name="input-pass"
              v-model="$v.form.pass.$model"
              :state="validateState('pass')"
              aria-describedby="input-pass-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-pass-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>

        <div class="b-form-inline-group row">
          <label
            for="input-pos-description-1"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("PAGES.SALES_MANAGEMENT.POS.POS_DESCRIPTION1") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-pos-description-1"
              name="input-pos-description-1"
              v-model="$v.form.posDescription1.$model"
              :state="validateState('posDescription1')"
              aria-describedby="input-pos-description-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-pos-description-1-live-feedback"
              >{{
                $t("AUTH.VALIDATION.REQUIRED_FIELD")
              }}</b-form-invalid-feedback
            >
          </div>
        </div>

        <div class="b-form-inline-group row">
          <label
            for="input-pos-description-2"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("PAGES.SALES_MANAGEMENT.POS.POS_DESCRIPTION2") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-pos-description-2"
              name="input-pos-description-2"
              v-model="$v.form.posDescription2.$model"
              :state="validateState('posDescription2')"
              aria-describedby="input-pos-description-2-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-pos-description-2-live-feedback"
              >{{
                $t("AUTH.VALIDATION.REQUIRED_FIELD")
              }}</b-form-invalid-feedback
            >
          </div>
        </div>

        <div class="b-form-inline-group row align-items-center">
          <label
            for="input-system-defined"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
          >
            {{ $t("PAGES.SALES_MANAGEMENT.POS.SYSTEM_DEFINED") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-checkbox
              v-model="$v.form.hasSystemDefined.$model"
              value="true"
              unchecked-value="false"
              id="input-system-defined"
              name="input-system-defined"
            >
            </b-form-checkbox>
          </div>
        </div>

        <div class="b-form-inline-group row align-items-center">
          <label
            for="input-pos-setup"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
          >
            {{ $t("PAGES.SALES_MANAGEMENT.POS.POS_SETUP") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-checkbox
              v-model="$v.form.hasPosSetup.$model"
              value="true"
              unchecked-value="false"
              id="input-pos-setup"
              name="input-pos-setup"
            >
            </b-form-checkbox>
          </div>
        </div>

        <div class="b-form-inline-group row align-items-center">
          <label
            for="input-delivery-form"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
          >
            {{ $t("PAGES.SALES_MANAGEMENT.POS.DELIVERY_FORM") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-checkbox
              v-model="$v.form.deliveryForm.$model"
              value="true"
              unchecked-value="false"
              id="input-delivery-form"
              name="input-delivery-form"
            >
            </b-form-checkbox>
          </div>
        </div>

        <div class="b-form-inline-group row align-items-center">
          <label
            for="input-has-delivered"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
          >
            {{ $t("PAGES.SALES_MANAGEMENT.POS.HAS_DELIVERED") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-checkbox
              v-model="$v.form.hasDelivered.$model"
              value="true"
              unchecked-value="false"
              id="input-has-delivered-setup"
              name="input-has-delivered-setup"
            >
            </b-form-checkbox>
          </div>
        </div>

        <div class="b-form-inline-group row">
          <label
            for="input-reason"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("PAGES.SALES_MANAGEMENT.POS.UNDELIVERED_REASON") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-reason"
              name="input-reason"
              v-model="$v.form.undeliveryReason.$model"
              :state="validateState('undeliveryReason')"
              aria-describedby="input-reason-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-reason-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>

        <!-- <div class="b-form-inline-group row">
          <label
            for="input-company"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
          >
            {{ $t("COMPANY.COMPANY") }}
          </label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-select
              v-model="$v.form.company.$model"
              :options="companies"
              text-field="title"
              value-field="id"
              id="input-company"
              name="input-company"
            >
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("FORM.PLEASE_SELECT")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div> -->
      </div>
      <div class="d-flex card-footer justify-content-end">
        <button
          type="submit"
          class="btn btn-success"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          {{ buttonTitle }}
        </button>
        <b-button
          v-if="!isNew && showDeleteButton"
          class="ml-3"
          :variant="pos.isActive ? 'danger' : 'info'"
          @click.stop.prevent="$emit('delete-pos')"
        >
          {{ pos.isActive ? $t("COMMON.DEACTIVATE") : $t("COMMON.ACTIVATE") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// import Api from "@/services/api";

export default {
  mixins: [validationMixin],
  props: ["showDeleteButton"],
  data() {
    return {
      isNew: false,
      pos: {},
      companies: [],
      buttonTitle: null,
      form: {
        // company: null,
        model: null,
        serial: null,
        pass: null,
        posDescription1: null,
        posDescription2: null,
        hasSystemDefined: false,
        hasPosSetup: false,
        hasDelivered: false,
        undeliveryReason: null,
        deliveryForm: false,
        isActive: true
      }
    };
  },
  validations: {
    form: {
      // company: {},
      model: { required },
      serial: { required },
      hasSystemDefined: {},
      hasPosSetup: {},
      hasDelivered: {},
      undeliveryReason: {
        required: requiredIf(function(form) {
          return form.hasDelivered === true || form.hasDelivered === "true";
        })
      },
      deliveryForm: {},
      pass: {},
      posDescription1: {},
      posDescription2: {},
      pos: {},
      isActive: {}
    }
  },
  computed: {
    ...mapGetters({
      selectedPos: "pos/SELECTED_POS",
      selectedPosId: "pos/SELECTED_POS_ID"
    })
  },
  async mounted() {
    // await this.fetchCompanies();
    this.pos = this.selectedPosId ? this.selectedPos : false;
    this.setForm();
  },
  methods: {
    // async fetchCompanies() {
    //   const url = "companies?isActive=true&pos=null";

    //   const companies = await Api.get(url);
    //   this.companies = companies.data;
    // },
    async setForm() {
      const pos = this.pos;
      // const companyUri = pos.companyUri;

      if (pos && pos.id) {
        this.isNew = false;
        this.buttonTitle = this.$t("FORM.UPDATE");
        this.form.model = pos.model;
        this.form.serial = pos.serial;
        this.form.hasSystemDefined = pos.hasSystemDefined;
        this.form.hasPosSetup = pos.hasPosSetup;
        this.form.undeliveryReason = pos.undeliveryReason;
        this.form.deliveryForm = pos.deliveryForm;
        this.form.hasDelivered = pos.hasDelivered;
        this.form.pass = pos.pass;
        this.form.posDescription1 = pos.posDescription1;
        this.form.posDescription2 = pos.posDescription2;
        this.form.isActive = pos.isActive;

        // if (companyUri) {
        //   const delimiter = companyUri.lastIndexOf("/");
        //   const companyId = companyUri.substring(delimiter + 1);
        //   this.form.company = companyId;
        // }
      } else {
        this.buttonTitle = this.$t("FORM.SUBMIT");
      }
    },
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // if (this.form.company) {
      //   this.form.company = `/api/companies/${this.form.company}`;
      // }

      this.$emit("submit", this.form);
    }
  }
};
</script>
